<template>
  <div>
    <div v-show="contractsLength" class="mb-7">
      <div class="d-flex flex-wrap flex-stack mb-6">
        <h3 class="mb-0">Contracts</h3>
        <div class="d-flex flex-wrap" />
      </div>
      <pxs-proposals
        proposal-status="Signed"
        :excludedHeaders="['construction', 'address', 'city']"
        @on-load="onContractsLoad"
      />
    </div>

    <div v-show="proposalsLength" class="mb-7">
      <div class="d-flex flex-wrap flex-stack mb-6">
        <h3 class="mb-0">Proposals</h3>
        <div
          class="d-flex flex-wrap"
          :style="{
            height: '43px',
          }"
        >
          <px-btn
            v-if="selectedProposalsLength && selectedProposalsLength <= 3"
            size="sm"
            extended-classes="btn-flex me-2"
            @click.native="() => onBunchShare()"
          >
            <i class="bi bi-share me-1" />
            Share
          </px-btn>
          <div
            v-if="selectedProposalsLength && selectedProposalsLength > 3"
            class="d-inline-block me-2"
            v-tooltip="{
              content: 'You should select maximum 3 proposals!',
              delay: { show: 400, hide: 0 },
              placement: 'top',
            }"
          >
            <px-btn size="sm" extended-classes="btn-flex" disabled>
              <i class="bi bi-share me-1" />
              Share
            </px-btn>
          </div>
          <px-btn
            v-if="selectedProposalsLength"
            extended-classes="btn-flex btn-sm"
            :in-process="archiveInProcess"
            :disabled="archiveInProcess"
            @click.native="onBunchArchive"
          >
            <i class="bi bi-archive me-1" />
            Archive
          </px-btn>
        </div>
      </div>
      <pxs-proposals
        ref="proposals"
        proposal-status="not.Signed"
        :selectable="true"
        :excludedHeaders="['construction', 'address', 'city']"
        @on-load="onProposalsLoad"
        @on-row-check="onProposalsRowCheck"
      />
    </div>

    <div v-show="changeOrdersLength">
      <div class="d-flex flex-wrap flex-stack mb-6">
        <h3 class="mb-0">Change Orders</h3>
        <div class="d-flex flex-wrap" />
      </div>
      <pxs-change-orders :excluded-headers="['title']" @on-load="onChangeOrdersLoad" />
    </div>

    <div v-if="!contractsLength && !proposalsLength && !changeOrdersLength" class="position-relative min-h-200px">
      <div class="card mb-0">
        <div class="card-body">
          <px-placeholder />
        </div>
      </div>
    </div>

    <px-new-modal modal-size="mw-650px" :is-visible="isShareModalOpened" :close-modal="closeSharedProposalModal">
      <template #title>
        <h3>Share Selected Proposals</h3>
      </template>
      <template #content>
        <pxm-share-project
          :item-id="sharedProjectId"
          :selected-proposals-ids="selectedProposals"
          @onClose="closeSharedProposalModal"
        />
      </template>
    </px-new-modal>
  </div>
</template>

<script>
import PxsProposals from '@/sections/PxsProposals';
import PxsChangeOrders from '@/sections/PxsChangeOrders';

import PxmShareProject from '@/modals/PxmShareProject';
import { mapActions } from 'vuex';

export default {
  components: {
    PxsProposals,
    PxsChangeOrders,

    PxmShareProject,
  },
  data() {
    return {
      contractsLength: false,
      proposalsLength: false,
      changeOrdersLength: false,

      selectedProposals: [],
      selectedProposalsLength: 0,

      sharedProjectId: null,
      isShareModalOpened: false,

      archiveInProcess: false,
    };
  },
  methods: {
    ...mapActions({
      actionMarkAsShared: 'proposals/markAsShared',
    }),
    onContractsLoad(data) {
      this.contractsLength = data > 0;
    },
    onProposalsLoad(data) {
      this.proposalsLength = data > 0;
    },
    onChangeOrdersLoad(data) {
      this.changeOrdersLength = data > 0;
    },

    onBunchShare() {
      this.isShareModalOpened = true;
    },
    async onBunchArchive() {
      this.archiveInProcess = true;

      await this.$refs.proposals.bunchArchiveProposals(this.selectedProposals);

      this.archiveInProcess = false;

      this.selectedProposals = [];
      this.selectedProposalsLength = 0;
    },

    onProposalsRowCheck(data) {
      this.selectedProposals = data;
      this.selectedProposalsLength = data.length;
    },

    closeSharedProposalModal(data) {
      this.isShareModalOpened = false;

      if (data && data.afterClose === 'close-and-unselect-rows') {
        this.actionMarkAsShared(data.proposals);

        this.selectedProposals = [];
        this.selectedProposalsLength = 0;

        this.$refs.proposals.filtered();
        this.$refs.proposals.clearSelected();
      }
    },
  },
  mounted() {
    this.sharedProjectId = this.$route.params.id;
  },
};
</script>
