<template>
  <div class="position-relative min-h-200px">
    <px-loader v-if="isLoading" :is-full-window="false" bg="blue" />
    <form v-if="!isLoading && targetProject" class="form" novalidate="novalidate">
      <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-medium mb-8">
        <li class="nav-item">
          <a
            :class="[
              'nav-link text-active-primary ms-0 me-lg-8 py-3',
              { active: currentTab === 'kt_modal_share_tab_pane_1' },
            ]"
            href="#kt_modal_share_tab_pane_1"
            data-bs-toggle="tab"
          >
            Send
          </a>
        </li>
        <li class="nav-item">
          <a
            :class="[
              'nav-link text-active-primary ms-0 me-lg-8 py-3',
              { active: currentTab === 'kt_modal_share_tab_pane_2' },
            ]"
            href="#kt_modal_share_tab_pane_2"
            data-bs-toggle="tab"
          >
            Get Share Link
          </a>
        </li>
      </ul>

      <div class="tab-content" id="myTabContent">
        <div
          id="kt_modal_share_tab_pane_1"
          :class="['tab-pane fade', { 'show active': currentTab === 'kt_modal_share_tab_pane_1' }]"
          role="tabpanel"
        >
          <div class="bg-light-primary p-4 rounded mb-8">
            <div class="row justify-content-between">
              <div class="col-lg-8 mb-4 mb-lg-0">
                <div class="pe-lg-10">
                  <div class="text-uppercase text-ls text-muted fs-10">Address:</div>
                  <strong
                    class="fw-medium text-truncate w-100 d-block"
                    v-tooltip="{
                      content: '!-----!',
                      delay: { show: 400, hide: 0 },
                    }"
                  >
                    {{ targetProject.address }}
                  </strong>
                </div>
              </div>

              <div class="col-lg-auto">
                <div class="text-uppercase text-ls text-muted fs-10">Name:</div>
                <strong class="fw-medium">
                  {{ targetProject.first_name }}
                  {{ targetProject.last_name }}
                </strong>
              </div>
            </div>
          </div>

          <!--begin::Form-->
          <form class="form" novalidate="novalidate">
            <!--begin::Input group-->
            <div v-if="form.receivers_v" class="position-relative mb-8">
              <label class="form-label fw-semi-bold mb-2">To</label>
              <input class="form-control form-control-solid" type="email" v-model="form.receivers_v" readonly />
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div v-if="targetProjectProposals" class="position-relative mb-8">
              <label class="form-label fw-semi-bold mb-2">Proposal(s)</label>
              <px-select
                v-if="form.proposals_v"
                v-model="form.proposals_v"
                classes="form-select form-select-solid"
                placeholder="Select proposal(s)"
                :items="targetProjectProposals.data"
                multiple
                track-by="id"
                :text-by="[
                  'attributes.number',
                  ' ',
                  '|',
                  ' ',
                  'attributes.scope_type',
                  ' ',
                  '|',
                  ' ',
                  'v',
                  'attributes.version',
                ]"
                select2-id="select2_proposals"
                @input="changeProposals"
              />
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="mb-8">
              <label class="form-label fw-semi-bold mb-2">Subject</label>
              <textarea-autosize
                v-model="form.subject_v"
                class="form-control form-control-solid"
                rows="1"
                :min-height="42"
                :max-height="500"
              />
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="mb-8">
              <label class="form-label fw-semi-bold mb-2">Email Template</label>
              <px-select
                v-model="form.selected_template_v"
                classes="form-select form-select-solid"
                placeholder="Select template"
                :items="templates.data"
                track-by="id"
                :text-by="['attributes.name']"
                :minimum-results-for-search="Infinity"
                select2-id="select2_template"
                @input="(val) => setTemplate(val)"
              />
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="mb-8">
              <label class="form-label fw-semi-bold mb-2">Message</label>
              <textarea-autosize
                v-model="customizedTemplate"
                class="form-control form-control-solid"
                rows="1"
                :min-height="42"
                :max-height="500"
              />
            </div>

            <div
              v-if="attachedSpecifications && attachedSpecifications.data && attachedSpecifications.data.length"
              class="position-relative mb-8"
            >
              <label class="form-label fw-semi-bold mb-2">Attach PDF from Content Library</label>
              <px-select
                v-model="form.attached_specifications_v"
                classes="form-select form-select-solid"
                placeholder="Select file(s)"
                :items="attachedSpecifications.data"
                multiple
                track-by="id"
                :text-by="['attributes.name']"
                select2-id="select2_files"
              />
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="mb-8">
              <label class="form-check-sm form-check form-check-custom form-check-solid">
                <input v-model="form.attach_the_invoice_as_a_pdf_v" class="form-check-input" type="checkbox" />
                <span class="form-check-label">Attach the Proposal(s) as PDF</span>
              </label>
            </div>
            <!--end::Input group-->

            <!--begin::Actions-->
            <div class="d-flex flex-stack pt-4">
              <px-btn color="light" extended-classes="btn-active-light-primary me-2" @click.native="hideModal()">
                Cancel
              </px-btn>

              <div>
                <px-btn :in-process="isSharingInProcess" :disabled="isSharingInProcess" @click.native="onSend">
                  {{ isSharingInProcess ? 'PDF is Generating' : 'Send' }}
                </px-btn>
              </div>
            </div>
            <!--end::Actions-->
          </form>
          <!--end::Form-->
        </div>
        <div
          id="kt_modal_share_tab_pane_2"
          :class="['tab-pane fade', { 'show active': currentTab === 'kt_modal_share_tab_pane_2' }]"
          role="tabpanel"
        >
          <p class="mb-4">
            Your customer can view the proposal at this link:
            <br />
            <router-link :to="{ name: 'project-preview', params: { id: targetProject.secret } }" target="_blank">
              {{ urlForCopy() }}
            </router-link>
          </p>

          <div class="d-flex mb-2">
            <div class="position-relative w-100">
              <input
                :class="[
                  'form-control form-control-solid me-3 flex-grow-1 pe-10',
                  { 'bg-success text-inverse-success': isLinkCopied },
                ]"
                type="text"
                :value="urlForCopy()"
                readonly
              />

              <px-btn
                v-if="!isLinkCopied"
                color="transparent"
                extended-classes="btn-icon btn-active-color-primary position-absolute top-0 end-0 h-100"
                v-tooltip="{
                  content: 'Copy Link',
                  delay: { show: 400, hide: 0 },
                }"
                title="Copy Link"
                @click.native="doCopy"
              >
                <span class="svg-icon svg-icon-muted svg-icon-2hx me-0">
                  <inline-svg src="/assets/media/icons/duotune/general/gen054.svg" />
                </span>
              </px-btn>
              <span v-else class="btn btn-icon position-absolute top-0 end-0 h-100">
                <i class="bi bi-check-lg text-white" />
              </span>
            </div>
          </div>
          <p class="small text-muted mb-8">Copy the link and share it with your customer.</p>

          <div class="d-flex mb-2">
            <div class="position-relative w-100">
              <input
                :class="[
                  'form-control form-control-solid me-3 flex-grow-1 pe-10',
                  { 'bg-success text-inverse-success': isCodeCopied },
                ]"
                type="text"
                :value="targetProject.code"
                readonly
              />

              <px-btn
                v-if="!isCodeCopied"
                color="transparent"
                extended-classes="btn-icon btn-active-color-primary position-absolute top-0 end-0 h-100"
                v-tooltip="{
                  content: 'Copy Code',
                  delay: { show: 400, hide: 0 },
                }"
                title="Copy Code"
                @click.native="doCodeCopy"
              >
                <span class="svg-icon svg-icon-muted svg-icon-2hx me-0">
                  <inline-svg src="/assets/media/icons/duotune/general/gen054.svg" />
                </span>
              </px-btn>
              <span v-else class="btn btn-icon position-absolute top-0 end-0 h-100">
                <i class="bi bi-check-lg text-white" />
              </span>
            </div>
          </div>
          <p class="small text-muted mb-8">Copy the code and share it with your customer.</p>

          <!--begin::Actions-->
          <div class="d-flex flex-stack pt-4">
            <px-btn color="light" extended-classes="btn-active-light-primary me-2" @click.native="hideModal()">
              Cancel
            </px-btn>

            <div>
              <px-btn color="light-primary" @click.native="doCopyExtended">Copy only</px-btn>
              <px-btn
                v-if="targetProject.pdf_file === null"
                extended-classes="ms-4"
                @click.native="doCopyExtendedAndSent"
              >
                Copy, and mark proposal as sent
              </px-btn>
            </div>
          </div>
          <!--end::Actions-->
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    itemId: {
      type: String,
      required: true,
    },
    selectedProposalsIds: {
      type: Array,
      required: true,
    },
  },
  validation: {
    form: {
      receivers_v: { required },
      proposals_v: { required },
      message_v: { required },
      subject_v: { required },
    },
  },
  data() {
    return {
      targetProjectProposals: null,
      currentTab: null,
      isLinkCopied: false,
      isCodeCopied: false,
      form: {
        receivers_v: null,
        proposals_v: [],
        selected_template_v: null,
        attached_specifications_v: [],
        message_v: '',
        subject_v: null,
        attach_the_invoice_as_a_pdf_v: false,
      },
      targetProject: null,
      isLoading: false,
      isSharingInProcess: false,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      singleProject: 'singleProject/project',
      message: 'proposalWizard/message',
      attachedSpecifications: 'attachedSpecifications/attachedSpecifications',
      templates: 'mailTextTemplates/mailTextTemplates',
    }),
    customizedTemplate: {
      get() {
        return this.form.message_v;
      },
      set(newValue) {
        const data = this.singleProject.attributes;

        if (this.form.message_v.includes('{fullname}')) {
          this.form.message_v = newValue.replaceAll('{fullname}', `${data.first_name} ${data.last_name}`);
        } else if (this.form.message_v.includes('{firstname}')) {
          this.form.message_v = newValue.replaceAll('{firstname}', `${data.first_name}`);
        } else {
          this.form.message_v = newValue;
        }
      },
    },
  },
  async mounted() {
    this.isLoading = true;

    await this.actionInitMailTextTemplates();
    await this.actionProjectsSingle(this.itemId).then((data) => {
      this.targetProject = data.data.data.attributes;
    });
    await this.actionProposalsGetAllById({
      subject_id: this.itemId,
      archive: false,
      current: true,
      per: 10000,
    }).then((data) => {
      this.targetProjectProposals = data;
    });

    this.form.selected_template_v = this.templates.data[0].id;
    this.form.receivers_v = this.targetProject.email;
    this.form.proposals_v = this.selectedProposalsIds;
    this.currentTab = 'kt_modal_share_tab_pane_1';

    this.changeProposals();
    this.setTemplate(this.form.selected_template_v);

    this.isLoading = false;
  },
  methods: {
    ...mapActions({
      actionProposalsGetAllById: 'proposals/getAllById',
      actionProjectsSingle: 'projects/single',
      actionProposalWizardShare: 'proposalWizard/share',
      actionInitMailTextTemplates: 'mailTextTemplates/index',
    }),
    setTemplate(id) {
      const content = this.templates.data.find((el) => el.id === id);

      this.form.message_v = content.attributes.text;
    },
    urlForCopy() {
      return `${this.getCurrentBaseUrl()}projects/preview/${this.targetProject.secret}`;
    },
    urlAndCodeForCopy() {
      return `Proposal Link : ${this.getCurrentBaseUrl()}projects/preview/${this.targetProject.secret}\nCode: ${
        this.targetProject.code
      }`;
    },
    getCurrentBaseUrl() {
      return `${window.location.protocol}//${window.location.host}/`;
    },
    getProjectProposals(proposals, ids) {
      const newProposals = [];

      proposals
        .filter((el) => ids.includes(el.id))
        .map((el) => {
          newProposals.push(`${this.scopeNameByParam(el.attributes.scope_type)} ${el.attributes.number}`);
        });

      return newProposals;
    },
    changeProposals() {
      // const proposals = this.getProjectProposals(this.targetProjectProposals.data, this.form.proposals_v);

      // this.form.subject_v = `Update Proposals: ${proposals.join(', ')}`;

      const updateProposalSubject = `${this.targetProject.address_line_1} - Update Project Proposal.`;

      this.form.subject_v = `${updateProposalSubject}`;
    },
    close() {
      this.$emit('onClose', {
        afterClose: 'just-close',
      });
    },
    async onSend() {
      this.isSharingInProcess = true;

      const sharedProposals = await this.actionProposalWizardShare({
        proposal_scope_ids: this.form.proposals_v,
        attached_specifications: this.form.attached_specifications_v,
        mails: this.form.receivers_v,
        message: this.form.message_v,
        attach_the_invoice_as_a_pdf: this.form.attach_the_invoice_as_a_pdf_v,
        subject: this.form.subject_v,
      });

      this.isSharingInProcess = false;

      await this.v_toast(this.message?.status, 'Sent successfully!', () => {
        this.$emit('onClose', {
          afterClose: 'close-and-unselect-rows',
          proposals: sharedProposals.data.data || [],
        });
      });
    },
    doCopy() {
      this.$copyText(this.urlForCopy()).then(() => {
        this.isLinkCopied = true;

        setTimeout(() => {
          this.isLinkCopied = false;
        }, 3000);
      });
    },
    doCodeCopy() {
      this.$copyText(this.targetProject.code).then(() => {
        this.isCodeCopied = true;

        setTimeout(() => {
          this.isCodeCopied = false;
        }, 3000);
      });
    },
    doCopyExtended() {
      this.$copyText(this.urlAndCodeForCopy());
    },
    doCopyExtendedAndSent() {
      this.$copyText(this.urlAndCodeForCopy()).then(() => {
        this.onSend(false);
      });
    },
  },
};
</script>
